/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Box, CircularProgress, Stack, styled } from '@mui/material'
import Modal from 'react-modal'
import _isEqual from 'lodash/isEqual'
import _isEmpty from 'lodash/isEmpty'
import { getFromBrowserStorage } from '@helpers/storage'
import { setupCheckoutAnalytics } from '@helpers/google-tag-manager'
import { getLineItems, getOrder, CHECKOUT_STEPS } from '@helpers/checkout/global'
import { getCurrentLocation } from '@helpers/geo-location'
import { getAddressSpecificBody, alertAndReturnToCart } from '@helpers/checkout/shipping-section'
import { setCart } from '@redux/modules/cart'
import { setOrder, setCheckoutStep, setCheckoutStepsCompleted, setDeliveryMode } from '@redux/modules/checkout'
import { hasSavedCoupon, getSavedCouponObj, getSavedCouponType } from '@services/coupon'
import { sentryLogger, setExtra, levels } from '@helpers/sentry-logger'
import { setCheckoutReviewStep } from '@helpers/checkout/local-storage'
import { getCart, checkProductZipAvailability, loadCartFromOrder } from '@helpers/cart'
import { usePrevious } from '@hooks/usePrevious'
import { CartAndCheckoutSpinnerWrapper, LoadingMessage } from '@shared/LoadingSpinner/CartAndCheckoutSpinner'

import loaderDark from '../../assets/images/loader-dark.svg'
import CondensedContact from '../shared/condensed-contact-links'
import CheckoutStep from './checkout-parts/checkout-steps'
import CheckoutEmpty from './checkout-parts/empty-checkout'
import DisabledContext from './checkout-parts/checkout-steps/disabledContext'
import ExpressCheckout from './checkout-parts/express-checkout'
import ShippingSectionWrapper from './checkout-parts/shipping-section'
import DeliverySectionWrapper from './checkout-parts/delivery-section'
import PaymentSectionWrapper from './checkout-parts/payment-section'
import ReviewSection from './checkout-parts/review-section'
import ReturnToCart from './checkout-parts/return-to-cart'
import DeclineModal from './checkout-parts/decline-modal'
import { createOrder, updateLineItems, updateAddress, updatePayment } from '../../lib/services/checkout'
import OrderSummaryWrapper from './checkout-parts/OrderSummaryWrapper'

const UNAVAILABLE_MESSAGE =
  'We apologize, but some items are out of stock or unavailable for purchase in the specified zip code. Please review your cart and make changes as necessary.'

const ExpressContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
  },
  maxWidth: '60%',
  padding: '16px',
  '& button': {
    height: '40px !important',
    minWidth: '50%',
  },
}))

const ContactContainer = styled(Stack)(() => ({
  margin: '0 auto',
  width: 'clamp(280px, 100%, 500px)',
  minWidth: '280px',
}))

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#___gatsby')
const Checkout = () => {
  const [loadingStep, setLoadingStep] = useState('')
  const [isDisabled, setIsDisabled] = useState(null)
  const disabled = { isDisabled, setIsDisabled }
  const { cart } = useSelector(state => state.cart, shallowEqual)
  const { order, checkoutStepsCompleted, declineModalInfo } = useSelector(state => state.checkout, shallowEqual)
  const dispatch = useDispatch()
  const previousOrder = usePrevious(order, true)
  const previousCart = usePrevious(cart, true)
  const storeCartLineItems = useMemo(
    () => (order?.lineItems ? order?.lineItems?.filter(lineItem => lineItem?.isStoreSku) : []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order],
  )
  const hasExpressPaymentApplied = useMemo(
    () => order.paymentInfo && order.paymentInfo.some(pay => pay.paymentType === 'PALV2' || pay.paymentType === 'APP'),
    [order],
  )
  const showCheckout = order && order.lineItems && order.lineItems.length > 0 && order.subtotal > 0
  const isPlacingOrder = !!order?.orderCart

  const cartItems = useMemo(() => {
    if (!cart) return getCart().cartItems
    return cart.cartItems
  }, [cart])

  useEffect(() => {
    if (typeof window !== 'undefined' && window.location) {
      if (window.location.href.indexOf('?checkout_token') > 0) {
        setCheckoutReviewStep()
        return
      }
    }

    const placingOrder = Object.keys(order).includes('orderCart') // orderCart is temporarily added to order between Place Order btn click and navigating to Order Success page
    if (!placingOrder && JSON.stringify(previousCart) !== JSON.stringify(cart)) {
      const location = getCurrentLocation()

      const orderStorage = getFromBrowserStorage('session', 'order')
      const checkoutStepStorage = getFromBrowserStorage('session', 'checkoutStep')
      const checkoutStepsCompletedStorage = getFromBrowserStorage('session', 'checkoutStepsCompleted')

      const lineItems =
        storeCartLineItems.length > 0 ? [...storeCartLineItems, ...getLineItems(cart)] : getLineItems(cart)

      const { shippingAddress, contact, storeCart } = orderStorage || order

      if (shippingAddress && contact && !_isEmpty(storeCart?.lineItems) && checkoutStepStorage !== 'review') {
        setLoadingStep(CHECKOUT_STEPS.delivery)
        if (!loadingStep)
          checkProductZipAvailability(storeCart.shippingAddress.zip).then(notAvailable => {
            if (!_isEmpty(notAvailable)) {
              alertAndReturnToCart(UNAVAILABLE_MESSAGE, CHECKOUT_STEPS.delivery)
            } else {
              const currentOrder = (orderStorage || order) ?? {}
              const hasDeliveryCalendar = currentOrder?.deliveryCalendar?.length
              updateAddress(getAddressSpecificBody(currentOrder))
                .then(data => {
                  if (data && data.orderId && !hasDeliveryCalendar && data?.deliveryCalendar?.length) {
                    dispatch(setOrder({ ...currentOrder, ...data }))
                  }
                })
                .catch(() => {
                  if (!hasDeliveryCalendar) dispatch(setDeliveryMode(null))
                })
                .finally(() => {
                  setLoadingStep('')
                })
            }
          })
        dispatch(setCheckoutStep(CHECKOUT_STEPS.delivery))
      } else if (!_isEmpty(lineItems) && checkoutStepStorage !== 'review') {
        updateLineItems({
          orderId: orderStorage.orderId,
          lineItems,
          region: location.region,
          zone: parseInt(location.price_zone),
          distribution_index: parseInt(location.distribution_index),
        })
          .then(orderResp => {
            if (orderResp && orderResp.orderId) {
              if (orderResp.total === 0) {
                dispatch(setOrder({ ...orderResp, total: orderStorage.total }))
              } else {
                const currentOrder = getOrder()
                if (currentOrder && currentOrder.paymentInfo) {
                  updatePayment({
                    orderId: currentOrder.orderId,
                    paymentInfo: currentOrder.paymentInfo,
                  }).then(data => {
                    if (data && data.orderId) {
                      dispatch(setOrder(data))
                    }
                  })
                } else {
                  dispatch(setOrder(orderResp))
                }
              }
            }
          })
          .catch(error => {
            checkProductZipAvailability(shippingAddress.zip).then(notAvailable => {
              if (!_isEmpty(notAvailable)) {
                alertAndReturnToCart(UNAVAILABLE_MESSAGE, CHECKOUT_STEPS.delivery)
              }
            })
          })
          .finally(() => {
            dispatch(
              setCheckoutStep(
                Object.keys(checkoutStepStorage).length === 0 ? CHECKOUT_STEPS.shipping : checkoutStepStorage,
              ),
            )
          })
      } else {
        dispatch(
          setCheckoutStep(
            Object.keys(checkoutStepStorage).length === 0 ? CHECKOUT_STEPS.shipping : checkoutStepStorage,
          ),
        )
      }

      if (order && checkoutStepsCompletedStorage && checkoutStepsCompleted !== checkoutStepsCompletedStorage) {
        dispatch(setCheckoutStepsCompleted(checkoutStepsCompletedStorage))
      }
    }
  }, [cartItems])

  useEffect(() => {
    const cartStorage = getFromBrowserStorage('local', 'cart')
    const tempOrder = getFromBrowserStorage('session', 'order')
    if (cartStorage && Object.keys(cartStorage).length > 0) {
      dispatch(setCart(cartStorage))
      setupCheckoutAnalytics(cartStorage, tempOrder)
    }
  }, [dispatch])

  useEffect(() => {
    const lineItems =
      storeCartLineItems.length > 0 ? [...storeCartLineItems, ...getLineItems(cart)] : getLineItems(cart)

    const orderStorage = getFromBrowserStorage('session', 'order')
    const orderFromStorageHasItems = orderStorage && orderStorage.lineItems && orderStorage.lineItems.length > 0
    const orderHasBeenUpdated = JSON.stringify(order) !== JSON.stringify(orderStorage)
    const hasDeprecatedPaymentType = checkPaymentType(orderStorage)
    const checkoutStepStorage = getFromBrowserStorage('session', 'checkoutStep')
    const location = getCurrentLocation()

    if (orderFromStorageHasItems && orderHasBeenUpdated && !hasDeprecatedPaymentType) {
      dispatch(setOrder(orderStorage))
    } else if (hasDeprecatedPaymentType || !orderStorage || (orderStorage && !orderStorage.orderId)) {
      const createOrderRequestBody = {
        lineItems,
        region: location.region,
        zone: parseInt(location.price_zone),
        distribution_index: parseInt(location.distribution_index),
      }

      /* check to see if we need to send a coupon to the backend during checkout */
      const hasCoupon = hasSavedCoupon()
      if (hasCoupon) {
        const couponObj = getSavedCouponObj()
        const couponType = getSavedCouponType()
        const customerCareCoupon = ['OFF', 'PER', 'DIS'].some(prefix => prefix === couponType)
        const { code: couponCode, id: couponId } = couponObj
        createOrderRequestBody.thankYouId = customerCareCoupon ? couponCode : couponId
      }

      createOrder(createOrderRequestBody).then(orderResp => {
        dispatch(setCheckoutStep('shipping'))
        if (orderResp && orderResp.orderId) {
          dispatch(setOrder(orderResp))
          if (cart?.cartItems?.some(item => !!item.associatedPackage)) {
            loadCartFromOrder(orderResp.orderId, true)
          }
        } else {
          sentryLogger({
            configureScope: {
              type: setExtra,
              level: levels.fatal,
              region: location.region,
              lineItems,
            },
            captureMessage: {
              message: 'Checkout - createOrder componentDidMount',
              level: levels.fatal,
            },
          })
        }
      })
    } else if (
      orderStorage &&
      orderStorage.orderId &&
      lineItems?.length > 0 &&
      !_isEqual(previousOrder?.lineItems, order?.lineItems) &&
      checkoutStepStorage !== 'review'
    ) {
      updateLineItems({
        orderId: orderStorage.orderId,
        lineItems,
        region: location.region,
        zone: parseInt(location.price_zone),
        distribution_index: parseInt(location.distribution_index),
      }).then(orderResp => {
        if (orderResp && orderResp.orderId) {
          if (cart?.cartItems?.some(item => !!item.associatedPackage)) {
            loadCartFromOrder(orderResp.orderId, true)
          }
          // the updatelineItems call in checkout step 'shipping' sometimes return 0 total. If that's the case, use previous total
          if (orderResp.total === 0) {
            dispatch(setOrder({ ...orderResp, total: orderStorage.total }))
          } else {
            const currentOrder = getOrder()
            if (currentOrder && currentOrder.paymentInfo) {
              updatePayment({
                orderId: currentOrder.orderId,
                paymentInfo: currentOrder.paymentInfo,
              }).then(data => {
                if (data && data.orderId) {
                  dispatch(setOrder(data))
                }
              })
            } else {
              dispatch(setOrder(orderResp))
            }
          }
        } else {
          sentryLogger({
            configureScope: {
              type: setExtra,
              level: levels.error,
              lineItems,
              action: 'component mount',
            },
            captureMessage: {
              message: 'Checkout - updateLineItems componentDidMount',
              level: levels.error,
            },
          })
        }
      })
    }
  }, [cartItems, previousOrder])

  const checkPaymentType = _order => {
    if (_order && _order.paymentInfo) {
      return _order.paymentInfo.some(payment => payment.paymentType === 'CYBER')
    }
    return false
  }

  return (
    <>
      {showCheckout ? (
        <section className="checkout-page">
          <ReturnToCart />
          <DisabledContext.Provider value={disabled}>
            <Stack>
              <Stack direction={{ xs: 'column', md: 'row' }} sx={{ width: '100%', position: 'relative' }} spacing={6}>
                <Box sx={{ flex: 2, minWidth: { xs: 'unset', md: '700px' } }}>
                  {isPlacingOrder ? (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                      <img alt="placing order" src={loaderDark} />
                    </div>
                  ) : (
                    <Stack sx={{ width: '100%' }}>
                      {!checkoutStepsCompleted.payment && !hasExpressPaymentApplied && (
                        <ExpressContainer>
                          <ExpressCheckout order={order} total={order.total} showPayPal />
                        </ExpressContainer>
                      )}
                      <CheckoutStep sectionNumber="1" sectionTitle="Shipping" sectionType="shipping">
                        <ShippingSectionWrapper />
                      </CheckoutStep>

                      <CheckoutStep
                        loadingStep={loadingStep === 'delivery'}
                        previousSection="shipping"
                        sectionNumber="2"
                        sectionTitle="Delivery"
                        sectionType="delivery"
                      >
                        <DeliverySectionWrapper />
                      </CheckoutStep>

                      <CheckoutStep
                        previousSection="delivery"
                        sectionNumber="3"
                        sectionTitle="Payment"
                        sectionType="payment"
                      >
                        <PaymentSectionWrapper />
                      </CheckoutStep>

                      <CheckoutStep
                        previousSection="payment"
                        sectionNumber="4"
                        sectionTitle="Review Order"
                        sectionType="review"
                      >
                        <ReviewSection />
                      </CheckoutStep>
                    </Stack>
                  )}
                </Box>

                <div style={{ flex: 1, position: 'relative' }}>
                  <OrderSummaryWrapper order={order} />
                </div>
              </Stack>

              <ContactContainer sx={{ display: { xs: 'flex', md: 'none' } }}>
                <CondensedContact />
              </ContactContainer>
            </Stack>
          </DisabledContext.Provider>
        </section>
      ) : (
        <CartAndCheckoutSpinnerWrapper>
          <CircularProgress />
          <LoadingMessage>Loading Checkout</LoadingMessage>
        </CartAndCheckoutSpinnerWrapper>
      )}
      {order && order.lineItems && order.lineItems.length < 1 && <CheckoutEmpty />}
      {!order && (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img alt="checkout loading" src={loaderDark} />
        </div>
      )}
      <DeclineModal
        modalOpen={declineModalInfo.declineModalOpen}
        multiTender={declineModalInfo.multiTender}
        responseCode={declineModalInfo.responseCode}
        type={declineModalInfo.declineType}
      />
    </>
  )
}

export default Checkout
