import React, { useState, useEffect, useMemo } from 'react'
import { array, string, number, oneOfType, bool, object, shape } from 'prop-types'
import { Navigation, Pagination, Scrollbar, A11y, FreeMode, Mousewheel, EffectCoverflow } from 'swiper-v9'
import { Swiper } from 'swiper-v9/react'
import { Box } from '@mui/material'
import { isMobile } from 'react-device-detect'
import { ChevronRight, ChevronLeft } from '@mui/icons-material'
import {
  ComponentWrapper,
  BaseSwiperWrapper,
  NavigationWrapper,
  NavigationButton,
  SwiperSlideSsr,
  generateSsrStyles,
  NavigationVariant1Wrapper,
  NavigationButtonVariant1,
  DesktopSwiperWrapper,
} from './BaseSwiper.styles'
import 'swiper-v9/css'
import 'swiper-v9/css/navigation'
import 'swiper-v9/css/pagination'
import 'swiper-v9/css/scrollbar'
import 'swiper-v9/css/free-mode'
import 'swiper-v9/css/mousewheel'

const BaseSwiper = ({
  title,
  slides,
  desktopSlides,
  mobileSlides,
  slidesPerViewDesktop = 'auto',
  slidesPerViewMobile = 'auto',
  spaceBetween = 10,
  spaceBetweenMobile = 10,
  gapToScrollbarDesktop,
  gapToScrollbarMobile,
  hideScrollbar,
  fadeScrollbar,
  swiperStyles,
  scrollbarStyles,
  fullWidthMobile,
  fullWidthMobileOffset: fullWidthMobileOffsetTemp,
  fullWidthDesktop,
  fullWidthDesktopOffset: fullWidthDesktopOffsetTemp,
  freeModeDesktop,
  freeModeMobile,
  displayDesktop = true,
  displayMobile = true,
  breakpoints,
  dataTestId,
  cssMode,
  centered,
  className,
  mobileZoomView,
  slidesOffsetMobile,
  navigationVariant = 'default',
  isDisplayFilter,
  activeIndex,
  isVisualFilter,
}) => {
  const [isSsr, setIsSsr] = useState(true)
  const mobileSlidesArr = mobileSlides ?? slides
  const desktopSlidesArr = desktopSlides ?? slides
  const [swiper, setSwiper] = useState(null)
  const [isBeginning, setIsBeginning] = useState(true)
  const [isEnd, setIsEnd] = useState(desktopSlidesArr?.length === 0)
  const [displayVariant1Nav, setDisplayVariant1Nav] = useState(false)
  const fullWidthDesktopOffset = fullWidthDesktop ? fullWidthDesktopOffsetTemp ?? 15 : 0
  const fullWidthMobileOffset = fullWidthMobile ? fullWidthMobileOffsetTemp ?? 15 : 0
  const absentScrollbarSpacing = isVisualFilter && isBeginning && isEnd

  useEffect(() => {
    setIsSsr(false)
  }, [])

  const ssrSwiperSlideDesktopStyles = useMemo(
    () => generateSsrStyles(breakpoints, spaceBetween, slidesPerViewDesktop),
    [breakpoints, spaceBetween, slidesPerViewDesktop],
  )

  const ssrSwiperSlideMobileStyles = useMemo(
    () => generateSsrStyles(breakpoints, spaceBetweenMobile, slidesPerViewMobile, mobileZoomView),
    [breakpoints, spaceBetweenMobile, slidesPerViewMobile, mobileZoomView],
  )

  const getBottomMarginSpacing = () => {
    if (hideScrollbar) return '0'
    // If scrollbar is not hidden and not needed
    return gapToScrollbarDesktop ?? (absentScrollbarSpacing ? '16px' : '40px')
  }

  const beginningEndUpdate = (currSwiper = swiper) => {
    if (currSwiper.isEnd) setIsEnd(true)
    else if (isEnd) setIsEnd(false)
    if (currSwiper.isBeginning) setIsBeginning(true)
    else if (isBeginning) setIsBeginning(false)
  }

  const handleSlideChange = currSwiper => {
    beginningEndUpdate(currSwiper)
  }

  const handlePrevClick = () => {
    swiper.slidePrev()
    beginningEndUpdate()
  }

  const handleNextClick = () => {
    swiper.slideNext()
    beginningEndUpdate()
  }

  const renderDefaultNavigation = () => (
    <NavigationWrapper fullWidthDesktopOffset={fullWidthDesktopOffset}>
      <NavigationButton
        aria-label={`Previous ${title ? `for ${title}` : 'image'}`}
        disabled={isBeginning}
        disableRipple
        onClick={handlePrevClick}
      >
        <ChevronLeft />
      </NavigationButton>
      <NavigationButton
        aria-label={`Next ${title ? `for ${title}` : 'image'}`}
        onClick={handleNextClick}
        disabled={isEnd}
        disableRipple
      >
        <ChevronRight />
      </NavigationButton>
    </NavigationWrapper>
  )

  const renderVariant1Navigation = () => (
    <>
      <NavigationVariant1Wrapper
        gapToScrollbarDesktop={gapToScrollbarDesktop}
        hideScrollbar={hideScrollbar}
        direction="left"
      >
        <NavigationButtonVariant1
          direction="left"
          aria-label={`Previous ${title ? `for ${title}` : 'image'}`}
          hideButton={isBeginning || !displayVariant1Nav}
          disableRipple
          onClick={handlePrevClick}
        >
          <ChevronLeft />
        </NavigationButtonVariant1>
      </NavigationVariant1Wrapper>
      <NavigationVariant1Wrapper
        gapToScrollbarDesktop={gapToScrollbarDesktop}
        hideScrollbar={hideScrollbar}
        direction="right"
      >
        <NavigationButtonVariant1
          direction="right"
          aria-label={`Next ${title ? `for ${title}` : 'image'}`}
          onClick={handleNextClick}
          hideButton={isEnd || !displayVariant1Nav}
          disableRipple
        >
          <ChevronRight />
        </NavigationButtonVariant1>
      </NavigationVariant1Wrapper>
    </>
  )

  const renderDesktopSwiper = () => (
    <DesktopSwiperWrapper
      onMouseEnter={() => navigationVariant === 'variant1' && setDisplayVariant1Nav(true)}
      onMouseLeave={() => navigationVariant === 'variant1' && setDisplayVariant1Nav(false)}
      isDisplayFilter={isDisplayFilter}
    >
      <BaseSwiperWrapper
        gapToScrollbarDesktop={gapToScrollbarDesktop}
        slidesPerViewDesktop={slidesPerViewDesktop}
        swiperStyles={swiperStyles}
        scrollbarStyles={scrollbarStyles}
        fullWidthDesktop={fullWidthDesktop}
        fullWidthDesktopOffset={fullWidthDesktopOffset}
        navigationVariant={navigationVariant}
        isDisplayFilter={isDisplayFilter}
        bottomMarginSpacing={getBottomMarginSpacing()}
        forceCenter={isSsr && centered}
      >
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y, FreeMode, Mousewheel]}
          onSwiper={swiperInstance => setSwiper(swiperInstance)}
          onSlideChange={currSwiper => handleSlideChange(currSwiper)}
          onUpdate={currSwiper => beginningEndUpdate(currSwiper)}
          onResize={currSwiper => beginningEndUpdate(currSwiper)}
          onTouchEnd={currSwiper => beginningEndUpdate(currSwiper)}
          onScrollbarDragEnd={currSwiper => beginningEndUpdate(currSwiper)}
          onScroll={currSwiper => beginningEndUpdate(currSwiper)}
          longSwipesRatio={0.25}
          spaceBetween={spaceBetween}
          scrollbar={{
            hide: false,
            draggable: true,
          }}
          slidesPerView={slidesPerViewDesktop}
          freeMode={{
            enabled: freeModeDesktop,
            minimumVelocity: 0.01,
            momentumRatio: 0.5,
          }}
          mousewheel={{
            forceToAxis: true,
          }}
          grabCursor
          slidesOffsetBefore={fullWidthDesktopOffset ?? 0}
          slidesOffsetAfter={fullWidthDesktopOffset ?? 0}
          breakpoints={breakpoints}
          centerInsufficientSlides={!isSsr && centered}
          style={{ maxWidth: isDisplayFilter ? '1356px' : null }}
        >
          {desktopSlidesArr.map((slide, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <SwiperSlideSsr key={`desktop-slide-${idx}`} ssrSwiperSlideStyles={isSsr && ssrSwiperSlideDesktopStyles}>
              {slide}
            </SwiperSlideSsr>
          ))}
          {navigationVariant === 'default' && !hideScrollbar && !(isBeginning && isEnd) && renderDefaultNavigation()}
        </Swiper>
      </BaseSwiperWrapper>
      {navigationVariant === 'variant1' && !(isBeginning && isEnd) && renderVariant1Navigation()}
    </DesktopSwiperWrapper>
  )

  const renderMobileSwiper = () => (
    <BaseSwiperWrapper
      gapToScrollbarMobile={gapToScrollbarMobile}
      hideScrollbar={hideScrollbar}
      swiperStyles={swiperStyles}
      scrollbarStyles={scrollbarStyles}
      slidesPerViewMobile={slidesPerViewMobile}
      fullWidthMobile={fullWidthMobile}
      fullWidthMobileOffset={fullWidthMobileOffset}
      freeModeMobile={freeModeMobile}
      forceCenter={isSsr && centered}
    >
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y, FreeMode, Mousewheel, EffectCoverflow]}
        scrollbar={{
          hide: !isSsr && fadeScrollbar,
        }}
        slidesPerView={slidesPerViewMobile}
        grabCursor
        spaceBetween={spaceBetweenMobile}
        freeMode={{
          enabled: freeModeMobile,
          minimumVelocity: 0.01,
          momentumRatio: 1,
          momentumVelocityRatio: 1,
        }}
        mousewheel={{
          forceToAxis: true,
        }}
        longSwipesRatio={0.25}
        slidesOffsetBefore={!mobileZoomView && !freeModeMobile && (slidesOffsetMobile ?? fullWidthMobileOffset)}
        slidesOffsetAfter={!mobileZoomView && !freeModeMobile && (slidesOffsetMobile ?? fullWidthMobileOffset)}
        breakpoints={breakpoints}
        cssMode={cssMode && isMobile}
        style={{ marginLeft: isSsr && fullWidthMobileOffset && !freeModeMobile ? `${fullWidthMobileOffset}px` : 0 }}
        centerInsufficientSlides={!isSsr && centered}
        centeredSlidesBounds={isVisualFilter}
        effect={mobileZoomView ? 'coverflow' : null}
        coverflowEffect={{
          rotate: 0,
          slideShadows: false,
          stretch: 0,
          scale: 0.9,
        }}
        centeredSlides={mobileZoomView || (isVisualFilter && activeIndex)}
        initialSlide={isDisplayFilter && isVisualFilter ? activeIndex : false}
      >
        {mobileSlidesArr.map((slide, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <SwiperSlideSsr key={`mobile-slide-${idx}`} ssrSwiperSlideStyles={isSsr && ssrSwiperSlideMobileStyles}>
            {slide}
          </SwiperSlideSsr>
        ))}
      </Swiper>
    </BaseSwiperWrapper>
  )

  return (
    <>
      {displayDesktop && desktopSlidesArr && desktopSlidesArr.length !== 0 && (
        <Box
          className={className}
          sx={{ display: { xs: 'none', sm: 'block', md: 'block' }, zIndex: 0 }}
          data-testid={`desktop-${dataTestId}`}
        >
          <ComponentWrapper width="100%" position="relative" swiperStyles={swiperStyles}>
            {renderDesktopSwiper()}
          </ComponentWrapper>
        </Box>
      )}
      {displayMobile && mobileSlidesArr && mobileSlidesArr.length !== 0 && (
        <Box
          className={className}
          sx={{ display: { md: 'none', sm: 'none', xs: 'block' }, zIndex: 0 }}
          data-testid={`mobile-${dataTestId}`}
        >
          <ComponentWrapper width="100%" position="relative" swiperStyles={swiperStyles}>
            {renderMobileSwiper()}
          </ComponentWrapper>
        </Box>
      )}
    </>
  )
}

export default BaseSwiper

BaseSwiper.propTypes = {
  title: string,
  slides: array,
  desktopSlides: array,
  mobileSlides: array,
  slidesPerViewDesktop: oneOfType([string, number]),
  slidesPerViewMobile: oneOfType([string, number]),
  spaceBetween: number,
  spaceBetweenMobile: number,
  gapToScrollbarDesktop: string,
  gapToScrollbarMobile: string,
  swiperStyles: shape({
    backgroundColor: string,
    padding: string,
  }),
  scrollbarStyles: shape({
    thickness: number,
  }),
  hideScrollbar: bool,
  fadeScrollbar: bool,
  freeModeDesktop: bool,
  freeModeMobile: bool,
  // fullWidth props are in beta and are buggy. don't use.
  fullWidthMobile: bool,
  fullWidthMobileOffset: number,
  fullWidthDesktop: bool,
  fullWidthDesktopOffset: number,
  displayDesktop: bool,
  displayMobile: bool,
  breakpoints: object,
  dataTestId: string,
  // cssMode helps swipes on mobile when slidesPerView is in auto
  cssMode: bool,
  centered: bool,
  className: string,
  mobileZoomView: bool,
  // use to offset without fullWidthMobile
  slidesOffsetMobile: number,
  navigationVariant: string,
  activeIndex: number,
  isDisplayFilter: bool,
  isVisualFilter: bool,
}
