/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useRef, useState } from 'react'
import { bool, object, string } from 'prop-types'
import scriptLoader from 'react-async-script-loader'
import { useDispatch } from 'react-redux'
import Recaptcha from 'react-recaptcha'
import useRecaptchaLoader from '@helpers/hooks/useRecaptchaLoader'
import { Button } from '@mui/material'
import { setCheckoutStep as actionCheckoutStep, setOrder } from '@redux/modules/checkout'
import { setCheckoutStep } from '@helpers/checkout/global'
import { updatePayment } from '@services/checkout'
import loaderLight from '../../../../assets/images/loader-light.svg'

const isSpaceOrEnterKey = key => [' ', 'Enter', 'NumpadEnter', 'Spacebar'].includes(key)

const SITE_KEY = process.env.GATSBY_RECAPTCHA_SITEKEY

// TODO ACIMA - resolve Acima iframe closing on background click (cannot add a wrapper since the iframe is appended to the body element)
// TODO ACIMA - add logic to the else {} in the useEffect for the case where the Acima script fails to load (i.e., isScriptLoadSucceed is falsey)

const AcimaButton = ({
  customer,
  isDisabled,
  isLoading,
  isMobile,
  isScriptLoaded,
  isScriptLoadSucceed,
  orderId,
  transaction,
}) => {
  const [handleButton, setHandleButton] = useState(null)
  const [acimaLoaded, setAcimaLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const acimaMessage = useRef()
  const denied = useRef(false)
  const dispatch = useDispatch()
  const { isWindow, isScriptLoaded: isRecaptchaScriptLoaded } = useRecaptchaLoader()
  const recaptchaRef = useRef(null)
  const recaptchaTokenRef = useRef(null)

  const verifyCallback = token => {
    recaptchaTokenRef.current = token
  }

  const handleSuccessfulLease = paymentProperties => {
    setLoading(true)
    updatePayment({ orderId, paymentInfo: [{ paymentType: 'ACIMA', paymentProperties }] })
      .then(finalOrder => {
        dispatch(setOrder({ ...finalOrder, selectedPaymentType: 'acima' }))
        setCheckoutStep(null, 'review', undefined, true, recaptchaTokenRef.current)
      })
      .catch(err => {
        // If Acima checkout completes successfully, but our updatePayment or placeOrder API calls fail to return
        // successfully, then this results in a "No-Charge" order. User would not get an order success email. Backend
        // team will be notified to manually create the order.
        console.error('Error setting payment >> ', err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const messageEvent = event => {
    const data = typeof event.data === 'string' && (JSON.parse(event.data) ?? false)
    if (data && data?.type !== 'ACIMA_ECOM_IFRAME_RESIZE') {
      if (data?.appStatus === 'Denied') {
        denied.current = true
        if (data?.type === 'ACIMA_ECOM_IFRAME_APPLICATION_STATUS') {
          acimaMessage.current = data
        }
      }
      if (data?.checkoutToken) {
        const { applicationId, checkoutToken, leaseId, leaseNumber } = data ?? {}
        if (applicationId && checkoutToken && leaseId && leaseNumber) {
          handleSuccessfulLease({ applicationId, checkoutToken, leaseId, leaseNumber })
        }
      }
    }
  }

  useEffect(() => {
    if (isScriptLoaded && !acimaLoaded) {
      if (isScriptLoadSucceed) {
        setAcimaLoaded(true)
        const options = {
          merchantId: process.env.GATSBY_ACIMA_MERCHANT_ID,
          source: 'other',
          platform: 'custom',
        }

        const handleDenied = () => {
          setLoading(true)
          updatePayment({
            orderId,
            paymentInfo: [
              {
                paymentType: 'ACIMA',
                status: 'Declined',
                paymentProperties: { ...acimaMessage.current },
              },
            ],
          })
            .then(finalOrder => {
              // TODO ACIMA - remove this additional updatePayment call once BE updates the handling of the declined call above so the payment actually gets removed and tax reset
              updatePayment({ orderId, paymentInfo: [] })
                .then(x => {
                  dispatch(setOrder({ ...x, selectedPaymentType: 'Credit' }))
                })
                .finally(() => {
                  dispatch(actionCheckoutStep('payment'))
                })
            })
            .catch(err => {
              console.error('Error resetting payment >> ', err)
            })
            .finally(() => {
              setLoading(false)
            })
        }

        const openAcimaModal = () => {
          const acima = new window.Acima.Client(options)

          acima
            .checkout({
              customer,
              transaction,
              isSuccessCallbackMode: false,
              onLeaseAgreementSigned: response => {
                denied.current = false
                acimaMessage.current = response
              },
              onInitialPayment: response => {
                denied.current = false
                acimaMessage.current = response
              },
              onClose: () => {},
            })
            .then(response => {
              acimaMessage.current = response
            })
            .catch(error => {
              if (error?.code !== 'CheckoutInterrupted') {
                console.error('Acima iframe error >>> ', error)
              }
            })
            .finally(() => {
              window.removeEventListener('message', messageEvent)
              if (denied.current) {
                handleDenied() // removes payment and returns user to step 3
              }
            })
        }

        setHandleButton(() => event => {
          event.stopPropagation()
          if (event.type === 'keydown' && !isSpaceOrEnterKey(event.key)) return null
          openAcimaModal()
          window.addEventListener('message', messageEvent)

          // The following hack is a quick fix for these z-index problems:
          // - in mobile view, the Acima iframe background has a lower z-index than the Mobile Order Summary
          //  drawer that slides up from the footer.
          // - in desktop view, the iframe has lower z-index than the Desktop Header Appbar
          setTimeout(() => {
            let acimaIframe
            let attempts = 0
            while (!acimaIframe && attempts < 10) {
              acimaIframe = document.querySelector('#iframe')
              attempts++ // eslint-disable-line
            }
            if (acimaIframe) {
              acimaIframe.style.zIndex = 2000 // elevates the Acima iframe backdrop above the mobile Order Summary and desktop header appbar
            }
          }, 1000)

          return null
        })
      } else {
        // onError('Something went wrong', 'microform script', 'Could not load microform script')
      }
    }
    return window.removeEventListener('message', messageEvent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acimaLoaded, acimaMessage, denied, isScriptLoaded, isScriptLoadSucceed])

  const showSpinner = isLoading || loading

  const handleClick = e => {
    if (!showSpinner) {
      recaptchaRef.current.execute()
      handleButton(e)
    }
  }

  return (
    <>
      {isWindow && isScriptLoaded && (
        <Recaptcha
          ref={recaptchaRef}
          render="explicit"
          sitekey={SITE_KEY}
          size="invisible"
          verifyCallback={verifyCallback}
        />
      )}
      <Button
        className="place-order-btn" // not for styling; used as a FullStory selector
        disabled={isDisabled}
        onClick={handleClick}
        onKeyDown={handleClick}
        sx={isMobile ? null : { width: '192px', height: showSpinner ? '62px' : 'unset' }}
        variant="contained"
      >
        {showSpinner ? <img style={{ height: 28 }} alt="Opening Acima Modal" src={loaderLight} /> : 'Place Your Order'}
      </Button>
    </>
  )
}

AcimaButton.propTypes = {
  customer: object,
  isDisabled: bool,
  isLoading: bool,
  isMobile: bool,
  isScriptLoaded: bool,
  isScriptLoadSucceed: bool,
  orderId: string,
  transaction: object,
}

export default scriptLoader([process.env.GATSBY_ACIMA_SDK_URL])(AcimaButton)
